<template>
  <!--  <div v-html="content">
      {{content}}
    </div>-->
  <div>
    <div class="header text-center mt-5">
      <img src="@/assets/images/logo/flipmoney.png" />
    </div>
    <vs-card class="container">
      <div class="mt-5">
          <h4 class="header">
          <strong>
           {{ legals.header }}
          </strong>
        </h4>
        <div v-html="legals.content" class="mt-5"></div>
      </div>
    </vs-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PrivacyPolicy",
  data() {
    return {
      legals: {
        header: "",
        content: "",
      },
    };
  },
  methods: {
    fetchAllLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchAllLegals")
        .then((result) => {
          this.$vs.loading.close();
          this.legals = this.getLegalByType("PRIVACY_POLICY_TC");
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
  },
  computed: {
    ...mapGetters("legals", ["getLegalByType"]),
  },
  mounted() {
    this.fetchAllLegals();
  },
};
</script>